@import './mobile-menu-btn.scss';

.mobile-menu {
  @extend %fixed;
  top: 0;
  // bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
 
  padding: calc(60px + var(--header--height)) 0 40px;

  color: var(--color--white);

  background-color: var(--color--dark);
  box-shadow: var(--box-shadow);
  transition: var(--transition-1);
  // overflow-y: scroll;
  // overflow-y: hidden;
  z-index: 20;

  &.isHidden {
    right: -101%;
    // bottom: auto;

    button, a {
      pointer-events: none;
      visibility: hidden;
    }
  }

  @include breakpoint-min($tablet ) {display: none;}
}
.mobile-menu::before {
  content: '';
  position: absolute;
  z-index: -1;

  top: 75%;
  left: 20%;
  width: 3000px;
  height: 1000px;
  transform: rotate(2.611deg);

  background: url('/static/images/icons/bg-line-hero.svg');
  background-repeat: no-repeat;
  background-position: top left;
}


.mobile-menu__container {
  @extend %flex-dc;
  height: 100%;
}
.mobile-menu__footer {
  margin-top: auto;
}

// .mobile-menu.isHidden button,
// .mobile-menu.isHidden a {
//   pointer-events: none;
//   visibility: hidden;
// }

// .mobile-menu__backdrop {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;

//   background-color: rgba(0, 0, 0, 0.9);
//   z-index: 10;
//   overflow: scroll;
// }


// .mobile-menu__btn {
//   position: relative;

//   @extend %flex-ac-jc;
//   width: 32px;
//   height: $height-header;
//   margin-left: auto;

//   background: none;
//   @extend %cursor;

//   &.isOpen {
//     justify-content: flex-start;
//   }

//   .header.isScrolling & {
//     height: 40px;
//   }

//   @include breakpoint-min($tablet) {
//     display: none;
//   }
// }

// .burger-line {
//   @extend %absolute;

//   width: 100%;
//   height: 3px;
//   margin: 0;

//   background-color: currentColor;
//   @extend %border-radius;

//   transition-duration: 0.4s;
//   transition-property: height, opacity, transform;

//   &:first-child {
//     transform: translateY(8px);
//   }

//   &:last-child {
//     transform: translateY(-8px);
//   }

//   .mobile-menu__btn.isOpen & {
//     &:first-child {
//       transform: rotate(-45deg);
//     }

//     &:nth-child(2n) {
//       opacity: 0;
//     }

//     &:last-child {
//       transform: rotate(45deg);
//     }
//   }
// }

// .mobile-menu {
//   position: fixed;
//   top: $height-header;
//   left: 0;
//   bottom: 0;
//   right: 0;

//   @extend %flex-dc;
//   padding: 28px 16px;

//   color: white;

//   background-color: rgba($color: black, $alpha: 0.9);
//   overflow-y: scroll;
//   z-index: 1001;
//   visibility: hidden;
//   pointer-events: none;
//   transform: translateX(100%);
//   transition: transform var(--transition__main),
//     visibility var(--transition__main);

//   body.isScrolling & {
//     top: 44px;
//   }

//   &.isOpen {
//     transform: translateX(0);
//     visibility: visible;
//     pointer-events: all;
//   }

//   @include breakpoint-min($tablet) {
//     left: 50%;
//   }
//   @include breakpoint-min($desktop) {
//     display: none;
//   }
// }
