*, :before, :after {
  box-sizing: border-box;
}

* {
  border: none;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  line-height: 1.5;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

img, picture, video, canvas, svg, iframe {
  max-width: 100%;
  display: block;
}

img {
  vertical-align: top;
}

ol, ul, li {
  list-style: none;
}

a, input, button, textarea, select {
  font: inherit;
}

button[disabled] {
  cursor: auto;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

a {
  text-decoration: none;
}

.link {
  color: inherit;
  text-decoration: none;
}

:root {
  --font--body: "Graphik LCG", "Arial", sans-serif;
  --color--dark: #191919;
  --color--dark-80: #191919cc;
  --color--dark-60: #19191999;
  --color--dark-20: #19191933;
  --color--dark-10: #1919191a;
  --color--white: #fff;
  --color--white-2: #f2f2f2;
  --color--white-10: #ffffff1a;
  --color--gray: #cdcecf;
  --color--gray-dark: #393c3c;
  --color--light-gray: #e5e5e5;
  --transition-1: .3s linear;
  --transition-parallax: all .2s cubic-bezier(.25, .74, .22, .99);
  --items: 1;
  --gap: 40px;
  --index: calc(1vh + 1vw);
}

.mobile-menu__btn, .btn-contact-me__icon-wrap, .btn-contact-me {
  justify-content: center;
  align-items: center;
  display: flex;
}

body, .mobile-menu__container, .mobile-menu__nav .nav__list {
  flex-direction: column;
  display: flex;
}

body, .btn-contact-me {
  position: relative;
}

.btn-contact-me__icon-wrap, .visually-hidden {
  position: absolute;
}

.mobile-menu {
  position: fixed;
}

button, a {
  cursor: pointer;
}

.mobile-menu__btn .burger-line {
  border-radius: 12px;
}

.btn-contact-me__icon-wrap, .lds-roller div:after {
  border-radius: 50%;
}

.visually-hidden {
  white-space: nowrap;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  border: 0;
  margin: -1px;
  padding: 0;
  overflow: hidden;
}

.isHidden .loader__backdrop {
  display: none;
}

.lds-roller {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.lds-roller div {
  transform-origin: 40px 40px;
  animation: 1.2s cubic-bezier(.5, 0, .5, 1) infinite lds-roller;
}

.lds-roller div:after {
  content: " ";
  width: 7px;
  height: 7px;
  background: var(--color--white);
  margin: -4px 0 0 -4px;
  display: block;
  position: absolute;
}

.lds-roller div:nth-child(1) {
  animation-delay: -.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.btn-contact-me {
  width: 120px;
  height: 32px;
  letter-spacing: .08px;
  text-transform: uppercase;
  color: var(--color--dark);
  border: 1px solid #0000;
  border-radius: 17px;
  gap: 16px;
  margin-right: 46px;
  font-size: 12px;
  font-weight: 500;
  transition: all .2s linear;
}

.btn-contact-me__icon-wrap {
  width: 30px;
  height: 30px;
  color: var(--color--dark);
  background-color: var(--color--white);
  border: 1px solid var(--color--dark-10);
  flex: 0 0 30px;
  transition: all .2s linear;
  right: -46px;
}

.header__btn-contact-me:hover, .header__btn-contact-me:focus, .header__btn-contact-me:hover .btn-contact-me__icon-wrap, .header__btn-contact-me:focus .btn-contact-me__icon-wrap {
  color: var(--color--white);
  border-color: var(--color--dark);
  background-color: var(--color--dark);
}

.header--dark .header__btn-contact-me:hover, .header--dark .header__btn-contact-me:focus, .header--dark .header__btn-contact-me:hover .btn-contact-me__icon-wrap, .header--dark .header__btn-contact-me:focus .btn-contact-me__icon-wrap {
  border-color: var(--color--white);
}

@media (max-width: 767px) {
  .header__btn-contact-me {
    display: none;
  }
}

.footer__btn-contact-me, .projects__btn-contact-me {
  border-color: var(--color--dark);
  background-color: var(--color--white);
}

.footer__btn-contact-me:hover, .footer__btn-contact-me:focus, .projects__btn-contact-me:hover, .projects__btn-contact-me:focus, .footer__btn-contact-me:hover .btn-contact-me__icon-wrap, .footer__btn-contact-me:focus .btn-contact-me__icon-wrap, .projects__btn-contact-me:hover .btn-contact-me__icon-wrap, .projects__btn-contact-me:focus .btn-contact-me__icon-wrap {
  color: var(--color--white);
  border-color: var(--color--dark);
  background-color: var(--color--dark);
}

.projects__btn-contact-me {
  margin-top: 16px;
}

@media (max-width: 767px) {
  .projects__btn-contact-me {
    margin-top: 0;
    position: absolute;
    bottom: 0;
  }
}

.footer__btn-contact-me {
  margin-bottom: 16px;
}

@media (max-width: 767px) {
  .footer__btn-contact-me {
    width: calc(100% - 48px);
    margin-bottom: 38px;
  }
}

.mobile-menu__btn-contact-me {
  background-color: var(--color--gray);
}

.mobile-menu__btn-contact-me:hover, .mobile-menu__btn-contact-me:focus, .mobile-menu__btn-contact-me:hover .btn-contact-me__icon-wrap, .mobile-menu__btn-contact-me:focus .btn-contact-me__icon-wrap {
  color: var(--color--white);
  border-color: var(--color--white);
  background-color: var(--color--dark);
}

.logo {
  height: 24px;
  letter-spacing: -1.4px;
  color: var(--color, --color--dark);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

@media (max-width: 1023px) {
  .header__logo .logo--long {
    display: none;
  }
}

@media (min-width: 1024px) {
  .header__logo .logo--short {
    display: none;
  }
}

.footer__logo .logo--long {
  display: none;
}

@media (max-width: 767px) {
  .footer__logo, .header__nav {
    display: none;
  }
}

.header__nav .nav__list {
  align-items: center;
  gap: var(--gap);
  display: flex;
}

.mobile-menu__nav .nav__list {
  gap: 24px;
}

.nav__link {
  position: relative;
}

.header__nav .nav__link {
  color: var(--color, --color--dark);
  letter-spacing: .09px;
  font-size: 14px;
  line-height: 94%;
}

.mobile-menu__nav .nav__link {
  color: var(--color, --color--white);
  letter-spacing: -1.06px;
  font-size: 32px;
  line-height: 132.5%;
}

.nav__link:before {
  content: "";
  width: 0%;
  height: 1px;
  background-color: var(--color);
  transition: all .2s linear;
  display: block;
  position: absolute;
  bottom: -4px;
  left: 0;
}

.nav__link:hover:before, .nav__link:focus:before {
  width: 100%;
}

.mobile-menu__btn {
  width: 48px;
  height: 48px;
  color: var(--color--black);
  background-color: #0000;
  position: relative;
}

body.mobileMenuIsOpen .mobile-menu__btn {
  z-index: 20;
}

@media (min-width: 768px) {
  .mobile-menu__btn {
    display: none;
  }
}

.mobile-menu__btn .burger-line {
  width: 28px;
  height: 2px;
  pointer-events: none;
  background-color: currentColor;
  margin: 0;
  transition-property: height, opacity, transform;
  transition-duration: .4s;
  position: absolute;
}

.burger-line:first-child {
  transform: translateY(8px);
}

.burger-line:last-child {
  transform: translateY(-8px);
}

body.mobileMenuIsOpen .mobile-menu__btn .burger-line:first-child {
  transform: rotate(-45deg);
}

body.mobileMenuIsOpen .mobile-menu__btn .burger-line:nth-child(2n) {
  opacity: 0;
}

body.mobileMenuIsOpen .mobile-menu__btn .burger-line:last-child {
  transform: rotate(45deg);
}

.mobile-menu {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  padding: calc(60px + var(--header--height)) 0 40px;
  color: var(--color--white);
  background-color: var(--color--dark);
  box-shadow: var(--box-shadow);
  transition: var(--transition-1);
  z-index: 20;
  top: 0;
  right: 0;
}

.mobile-menu.isHidden {
  right: -101%;
}

.mobile-menu.isHidden button, .mobile-menu.isHidden a {
  pointer-events: none;
  visibility: hidden;
}

@media (min-width: 768px) {
  .mobile-menu {
    display: none;
  }
}

.mobile-menu:before {
  content: "";
  z-index: -1;
  width: 3000px;
  height: 1000px;
  background: url("bg-line-hero.2bb22581.svg") 0 0 no-repeat;
  position: absolute;
  top: 75%;
  left: 20%;
  transform: rotate(2.611deg);
}

.mobile-menu__container {
  height: 100%;
}

.mobile-menu__footer {
  margin-top: auto;
}

body.isLoading, body.modalIsOpen, body.mobileMenuIsOpen {
  overflow: hidden;
}

body {
  --header--height: 64px;
  min-height: 100vh;
  min-height: var(--viewport-height);
  font-size: 16px;
  font-family: var(--font--body);
  color: var(--color--dark);
  background-color: var(--color--white);
}

main {
  scroll-snap-type: y mandatory;
  flex-grow: 1;
}

footer {
  margin-top: auto;
}

svg {
  fill: currentColor;
  pointer-events: none;
}

.section {
  scroll-snap-align: start;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
}

@media (min-width: 480px) {
  .container {
    width: 480px;
  }
}

@media (min-width: 768px) {
  .container {
    width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    width: 1024px;
    padding: 0 48px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1200px;
    padding: 0 80px;
  }
}

@media (min-width: 1440px) {
  .container {
    width: 1440px;
    padding: 0 80px;
  }
}

:focus {
  outline: var(--color--gray);
}

/*# sourceMappingURL=index.66c60f5b.css.map */
