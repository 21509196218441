:root {
  // ===== fonts =====
  --font--body: "Graphik LCG", "Arial", sans-serif;

  // ===== colors =====
  --color--dark: rgb(25, 25, 25); //#191919;
  --color--dark-80: rgba(25, 25, 25, 0.8); //#191919cc;
  --color--dark-60: rgba(25, 25, 25, 0.6); //#19191999;
  --color--dark-20: rgba(25, 25, 25, 0.2); //#19191933;
  --color--dark-10: rgba(25, 25, 25, 0.1); //#1919191a;
  --color--white: rgb(255, 255, 255); //#ffffff
  --color--white-2: #F2F2F2;
  --color--white-10: rgba(255, 255, 255, 0.1); //#ffffff1a
  --color--gray: rgb(205, 206, 207); // #cdcecf
  --color--gray-dark: rgb(57, 60, 60); // #393C3D
  --color--light-gray: rgb(229, 229, 229); // #e5e5e5

  // ===== transition =====
  --transition-1: 300ms linear;
  --transition-parallax: all 200ms cubic-bezier(.25,.74,.22,.99);

  // ===== card set ===
  --items: 1;
  --gap: 40px;
  --index: calc(1vh + 1vw);
}

// ===== breakpoints =====
$mobileS: 320px;
$mobileM: 375px;
$mobileL: 425px;
$mobile: 480px;
$tablet: 768px;
$laptop: 1024px;
$desktop: 1200px;
$desktopL: 1440px;
// ===== breakpoints =====
// ===== screen sizes ===
$small: 480px;
$medium: 768px;
$large: 1200px;
// ===== screen sizes ===

// ===== sizes ===
$padding-small: 16px;
$padding-medium: calc($padding-small * 2);
$border-radius: 12px;

