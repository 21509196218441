.logo {
  height: 24px;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -1.4px;
  color: var(--color, --color--dark);
}

.header__logo {
  @include breakpoint-max($laptop - 1) {
    & .logo--long {
      display: none;
    }
  }
  @include breakpoint-min($laptop) {
    & .logo--short {
      display: none;
    }
  }
}


.footer__logo {
  & .logo--long {
    display: none;
  }

  @include breakpoint-max($tablet - 1) {
    display: none;
  }
}
