.btn-contact-me {
  @extend %relative;

  @extend %flex-ac-jc;
  gap: 16px;
  width: 120px;
  height: 32px;
  margin-right: 46px;

  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.08px;
  text-transform: uppercase;
  color: var(--color--dark);

  border: 1px solid transparent;
  border-radius: 17px;
  transition: all 200ms linear;
}

.btn-contact-me__icon-wrap {
  @extend %absolute;
  right: -46px;

  flex: 0 0 30px;
  @extend %flex-ac-jc;
  width: 30px;
  height: 30px;

  @extend %border-radius-50;
  color: var(--color--dark);
  background-color: var(--color--white);
  border: 1px solid var(--color--dark-10);
  transition: all 200ms linear;
}


.header__btn-contact-me {
  &:hover, &:focus {
    color: var(--color--white);
    border-color: var(--color--dark);
    background-color: var(--color--dark);

    .btn-contact-me__icon-wrap { 
      color: var(--color--white);
      border-color: var(--color--dark);
      background-color: var(--color--dark);
    }
  }

  .header--dark &:hover, 
  .header--dark &:focus {
    border-color: var(--color--white);

    .btn-contact-me__icon-wrap { border-color: var(--color--white); }
  }

  @include breakpoint-max($tablet - 1) {display: none;}
}

.footer__btn-contact-me,
.projects__btn-contact-me {
  border-color: var(--color--dark);
  background-color: var(--color--white);

  &:hover, &:focus {
    color: var(--color--white);
    border-color: var(--color--dark);
    background-color: var(--color--dark);

    .btn-contact-me__icon-wrap { 
      color: var(--color--white);
      border-color: var(--color--dark);
      background-color: var(--color--dark);
    }
  }
}

.projects__btn-contact-me {
  margin-top: 16px;

  @include breakpoint-max($tablet - 1) {
    position: absolute;
    bottom: 0px;

    margin-top: 0;
  }
}

.footer__btn-contact-me {
  margin-bottom: 16px;

  @include breakpoint-max($tablet - 1) {
    width: calc(100% - 48px);
    margin-bottom: 38px;
  }
}



.mobile-menu__btn-contact-me {
  background-color: var(--color--gray);

  &:hover, &:focus {
    color: var(--color--white);
    border-color: var(--color--white);
    background-color: var(--color--dark);

    .btn-contact-me__icon-wrap { 
      color: var(--color--white);
      border-color: var(--color--white);
      background-color: var(--color--dark);
    }
  }
}
