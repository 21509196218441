@import './reset';
@import './var';
@import './utils.scss';
// small components styles
@import '/src/components/loader/loader.scss';
@import '/src/components/btns/btns.scss';
@import '/src/components/logo/logo.scss';
@import '/src/components/nav/nav.scss';
// components styles
@import '/src/components/mobile-menu/mobile-menu.scss';


// * { outline: 1px solid gold; }

body.isLoading,
body.modalIsOpen,
body.mobileMenuIsOpen {
  overflow: hidden;
}

body {
  --header--height: 64px;
  @extend %relative;

  @extend %flex-dc;
  min-height: 100vh;
  min-height: var(--viewport-height);

  font-size: 16px;
  font-family: var(--font--body);
  color: var(--color--dark);

  background-color: var(--color--white);
}
main {
  flex-grow: 1;
  scroll-snap-type: y mandatory;
}
footer {
  margin-top: auto;
}

svg {
  fill: currentColor;
  pointer-events: none;
}
button, a {
  @extend %cursor;
}

.section {
  scroll-snap-align: start;
}

.container {
  width: 100%;
  padding: 0 24px;
  margin: 0 auto;

  // @include breakpoint-min($mobileM) {
  //   width: $mobileM;
  // }
  @include breakpoint-min($mobile) {
    width: $mobile;
  }
  @include breakpoint-min($tablet) {
    width: $tablet;
  }
  @include breakpoint-min($laptop) {
    width: $laptop;
    padding: 0 48px;
  }
  @include breakpoint-min($desktop) {
    width: $desktop;
    padding: 0 80px;
  }
  @include breakpoint-min($desktopL) {
    width: $desktopL;
    padding: 0 80px;
  }
}

*:focus {
  outline: var(--color--gray);
}