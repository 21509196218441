.header__nav {
  @include breakpoint-max($tablet - 1) {
    display: none;
  }
}

.nav__list {
  .header__nav & {
    display: flex;
    align-items: center;
    gap: var(--gap);
  }

  .mobile-menu__nav & {
    @extend %flex-dc;
    gap: 24px;
  }
}

.nav__link {
  position: relative;
  .header__nav & {
    color: var(--color, --color--dark);
    font-size: 14px;
    line-height: 94%; /* 13.16px */
    letter-spacing: 0.09px;
  }

  .mobile-menu__nav & {
    color: var(--color, --color--white);
    font-size: 32px;
    line-height: 132.5%; /* 42.4px */
    letter-spacing: -1.06px;
  }
}

.nav__link::before {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;

  display: block;
  width: 0%;
  height: 1px;

  background-color: var(--color);
  transition: all 200ms linear;
}

.nav__link:hover::before,
.nav__link:focus::before {
  width: 100%;
}

